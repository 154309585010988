<template>
  <div style="height: 100%">
    <section id="main">
      <header id="title">
        <h1>NGUYEN CHI LINH</h1>
        <span class="subtitle"> SOFTWARE DEVELOPMENT AND TECHNICAL</span>
      </header>
      <section class="main-block concise">
        <!-- <h2><i class="fa fa-trophy"></i> OBJECTIVE</h2> -->
        <section class="blocks">
          <div class="date"></div>
          <!-- <div class="decorator"></div> -->
          <div class="details" style="padding-left: 0px">
            <header>
              <h3></h3>
              <span class="place"></span>
              <div style="line-height: 1.5; font-size: 8pt">
                With 6+ years of experience, my focus area has been full-stack
                development with .NET, VueJS/NextJS, and Flutter. My long-term
                goal is to be a technical expert and solution provider. In order
                to reach my goal, I will continue to develop my knowledge and
                improve my professional skills. I’m looking for an opportunity
                to work abroad for more international experience with good,
                open-minded people in a professional environment for future
                works.
              </div>
            </header>
          </div>
        </section>
      </section>
      <section class="main-block">
        <h2><i class="fa fa-folder-open"></i> PROJECTS</h2>
        <section class="blocks">
          <div class="date"><span>present</span><span>2021</span></div>
          <div class="decorator"></div>
          <div class="details">
            <header>
              <h3>CRA (Casino Regulatory Authority) & PHL SYSTEM</h3>
              <span class="place">Websparks Pte Ltd</span>
            </header>
            <div>
              <span
                ><i class="fa fa-code-fork" aria-hidden="true"></i>
                Descriptions</span
              ><br />
              <ul>
                <li>
                  For the Casino Regulatory Authority project (a statutory board
                  under the Ministry of Home Affairs of the Singapore
                  Government), a full stack engineer for implement CRA
                  functional for different terms of CO/HR users, responsible for
                  ensuring that the management and operation of the casinos in
                  Singapore remains free from criminal influence or exploitation
                  <ul>
                    <li>
                      Sitefinity CMS is used in the backend, while VueJS is used
                      in the frontend.
                    </li>
                    <li>
                      Developed the RESTful APIs to communicated with another
                      clients.
                    </li>
                  </ul>
                </li>
                <li>
                  Another PHL project included the implementation of all backed
                  functionalities as well as the creation of a website for an
                  insurance market company is used the Umbraco CMS.
                </li>
              </ul>
              <span class="place"
                ><i class="fa fa-code" aria-hidden="true"></i>
                Technologies</span
              ><br />
              <ul>
                <li>Sitefinity & Umbraco CMS with .NET.</li>
                <li>VueJS & NextJS.</li>
                <li>MSSQL Server & MongoDB Database.</li>
              </ul>
            </div>
          </div>
        </section>
        <section class="blocks">
          <div class="date"><span>2021</span></div>
          <div class="decorator"></div>
          <div class="details">
            <header>
              <h3>LINH.WORK</h3>
              <span class="place">Personal Project</span>
            </header>
            <div>
              <span
                ><i class="fa fa-code-fork" aria-hidden="true"></i>
                Descriptions</span
              ><br />
              <ul>
                <li>
                  www.linh.work - a minimal and simple personal blog and
                  portfolio website was created by me for exploring and writing
                  what I’ve learned interesting during my journey in
                  technologies and in life.
                </li>
                <li>
                  Built from NextJS & React and deployed to cloud service.
                </li>
                <li>Website: <b>https://www.linh.work</b></li>
              </ul>
              <span class="place"
                ><i class="fa fa-code" aria-hidden="true"></i>
                Technologies</span
              ><br />
              <ul>
                <li>NextJS & React.</li>
                <li>Typescript.</li>
                <li>Tailwind CSS.</li>
                <li>MDX.</li>
              </ul>
            </div>
          </div>
        </section>
        <section class="blocks">
          <div class="date"><span>2021</span></div>
          <div class="decorator"></div>
          <div class="details">
            <header>
              <h3>SACHTUI.COM</h3>
              <span class="place">Personal Project</span>
            </header>
            <div>
              <span
                ><i class="fa fa-code-fork" aria-hidden="true"></i>
                Descriptions</span
              ><br />
              <ul>
                <li>
                  A most friendly app with power features to let user read and
                  download books/comics and both apps got a lot of good comments
                  about performance from users.
                </li>
                <li>
                  Web application using .NET Core with Blazor and Web API:
                  <b>http://sachtui.com</b>
                </li>
                <li>
                  Cross Mobile application for Android and IOS using Flutter and
                  Web API:
                  <b
                    >https://play.google.com/store/apps/details?id=com.sachtui.sachtui</b
                  >
                </li>
              </ul>
              <span class="place"
                ><i class="fa fa-code" aria-hidden="true"></i>
                Technologies</span
              ><br />
              <ul>
                <li>.NET Core with Blazor & .NET Core Web API.</li>
                <li>EF Core & MSSQL Server.</li>
                <li>Flutter & Dart.</li>
              </ul>
            </div>
          </div>
        </section>
        <section class="blocks">
          <div class="date"><span>2021</span></div>
          <div class="decorator"></div>
          <div class="details">
            <header>
              <h3>NEWS ME</h3>
              <span class="place">Personal Project</span>
            </header>
            <div>
              <span
                ><i class="fa fa-code-fork" aria-hidden="true"></i>
                Descriptions</span
              ><br />
              <ul>
                <li>
                  An mobile app that provides readers with quick and easy access
                  to information via RSS feeds from major national and
                  international newspapers.
                </li>
                <li>
                  <b
                    >https://play.google.com/store/apps/details?id=com.linhnc.newsme</b
                  >
                </li>
              </ul>
              <span class="place"
                ><i class="fa fa-code" aria-hidden="true"></i>
                Technologies</span
              ><br />
              <ul>
                <li>.NET Core Web API</li>
                <li>Flutter</li>
                <li>EF Core & MSSQL Server.</li>
              </ul>
            </div>
          </div>
        </section>
        <section class="blocks">
          <div class="date"><span>2021</span><span>2016</span></div>
          <div class="decorator"></div>
          <div class="details">
            <header>
              <h3>RAPIDIS ERP SYSTEM</h3>
              <span class="place">A2 Automation Ltd</span>
            </header>
            <div>
              <span
                ><i class="fa fa-code-fork" aria-hidden="true"></i>
                Descriptions</span
              ><br />
              <ul>
                <li>
                  Develop numerous modules that handle sales, manufacturing,
                  warehousing, accounting, and other modules related in ERP
                  products and solutions.
                </li>
                <li>
                  Develop REST APIs to communicate with another ERP software
                  clients.
                </li>
                <li>
                  Migrate database from the old client ERP system to our ERP
                  system.
                </li>
                <li>
                  Integrate ASP.NET Web Form to ASP.NET MVC and .NET Core.
                </li>
                <li>Design User Interface (UI) and Crystal reports.</li>
                <li>
                  Provide technical skills and solution, manage project tasks
                  for the team members.
                </li>
              </ul>
              <span class="place"
                ><i class="fa fa-code" aria-hidden="true"></i>
                Technologies</span
              ><br />
              <ul>
                <li>ASP.NET Web Form, ASP.NET MVC, Web API and .NET Core.</li>
                <li>MSSQL Server Database.</li>
                <li>Telerik UI, SAP Crystal Report.</li>
              </ul>
            </div>
          </div>
        </section>
        <section class="blocks">
          <div class="date"><span>2016</span><span>2015</span></div>
          <div class="decorator"></div>
          <div class="details">
            <header>
              <h3>CT-EXPLORER</h3>
              <span class="place">FPT Software</span>
            </header>
            <div>
              <span
                ><i class="fa fa-code-fork" aria-hidden="true"></i>
                Descriptions</span
              ><br />
              <ul>
                <li>
                  Develop on CT-Scanner series, a system storage the information
                  of patients and handle the image processing of patients.
                </li>
                <li>
                  Improve experience: design document, coding, and testing.
                </li>
              </ul>
              <span class="place"
                ><i class="fa fa-code" aria-hidden="true"></i>
                Technologies</span
              ><br />
              <ul>
                <li>.NET WPF, MVVM Framework.</li>
                <li>MSSQL Server Database.</li>
              </ul>
            </div>
          </div>
        </section>
      </section>
      <section class="main-block">
        <h2><i class="fa fa-suitcase"></i> WORK EXPERIENCES</h2>
        <section class="blocks">
          <div class="date"><span>present</span><span>2021</span></div>
          <div class="decorator"></div>
          <div class="details">
            <header>
              <h3>Websparks Pte Ltd</h3>
              <span class="place">Senior Developer</span>
              <span class="location">Ho Chi Minh</span>
            </header>
            <div>
              <ul>
                <li>
                  Work with customers and teams in Singapore to develop and
                  customize of various content management system for finance,
                  insurance, casino project of the Singapore government.
                </li>
                <li>
                  Communicate with client users and product owners to understand
                  client domain and propose the technical solution that fit
                  client's environment.
                </li>
                <li>
                  Develop applications both front end web app and back end
                  services using different tech stacks.
                </li>
                <li>
                  Working in scrum team as developer but also able to
                  communicate well with product owner and provide technical
                  solutions.
                </li>
                <li>
                  Able to communicate with management team in client side and
                  technical teams in different vendors to support clients.
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section class="blocks">
          <div class="date"><span>2021</span><span>2016</span></div>
          <div class="decorator"></div>
          <div class="details">
            <header>
              <h3>A2 Automation Ltd</h3>
              <span class="place">Leader & Senior Developer</span>
              <span class="location">Bien Hoa</span>
            </header>
            <div>
              <ul>
                <li>
                  Develop and enhance of numerous modules that handle sales,
                  manufacturing, warehousing, accounting, and other modules
                  related in ERP products and solutions.
                </li>
                <li>
                  Understand the customer's business, to provide appropriate
                  solutions for large systems with lots of data
                </li>
                <li>
                  Ability to analyze system data and prepare migration, reports,
                  statements and projections.
                </li>
                <li>
                  Provide technical skills and solutions, manage small IT team
                  about 6 members.
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section class="blocks">
          <div class="date"><span>2016</span><span>2015</span></div>
          <div class="decorator"></div>
          <div class="details">
            <header>
              <h3>FPT Software</h3>
              <span class="place">Developer</span>
              <span class="location">Ho Chi Minh</span>
            </header>
            <div>
              <ul>
                <li>Develop for the CT-Scanner system series.</li>
                <li>
                  Joined the Fresher Programing and get the excellent
                  Certificate degree.
                </li>
              </ul>
            </div>
          </div>
        </section>
      </section>
      <section class="main-block concise">
        <h2><i class="fa fa-graduation-cap"></i> Education</h2>
        <section class="blocks">
          <div class="date"><span>2016</span><span>2011</span></div>
          <div class="decorator"></div>
          <div class="details">
            <header>
              <h3>Software Engineering</h3>
              <span class="place">University of Information Technology</span>
              <span class="location">Ho Chi Minh</span>
            </header>
            <div>
              Systematic application of engineering approaches to the
              development of software.
            </div>
          </div>
        </section>
      </section>
    </section>
    <aside id="sidebar">
      <div class="side-block" id="contact">
        <!-- <h1><i class="fa fa-id-badge" aria-hidden="true"></i> Contact Me</h1> -->
        <ul>
          <li><i class="fa fa-globe"></i> www.linh.work</li>
          <li>
            <a
              style="color: unset; text-decoration: unset"
              href="mailto: ncl.vnw@gmail.com"
              ><i class="fa fa-envelope"></i> ncl.vnw@gmail.com</a
            >
          </li>
          <li>
            <a
              style="color: unset; text-decoration: unset"
              target="_blank"
              href="https://www.linkedin.com/in/linhnc/"
              ><i class="fa fa-linkedin"></i> linkedin.com/in/linhnc</a
            >
          </li>
          <!-- <li><i class="fa fa-calendar"></i> May 28, 1993</li> -->
          <li><i class="fa fa-phone"></i> +84 0964449890</li>
          <li><i class="fa fa-location-arrow"></i> Dong Nai, Viet Nam</li>
        </ul>
      </div>
      <div class="side-block" id="skills" style="margin-top: 0px">
        <h1><i class="fa fa-bolt" aria-hidden="true"></i> Skills</h1>
        <p>
          Skilled in software development, provide technical skills and resolve
          problems. Strong development professional skills, enjoy and have
          ability to catch up new technology.
          <br />Mainly focus on the user experience while building a system. Due
          to having the system design mindset from scratch, my source code is
          both manageable and scalable. As a part of a team, always respect and
          listen to my teammates' opinions and also have strong critical
          thinking that helps me to debate for a better product.
        </p>

        <ul>
          <li style="list-style-type: square">
            <span style="font-weight: 500">Programming Languages</span>
            <ul>
              <li style="list-style-type: circle">.NET Programming: C#, VB.</li>
              <li style="list-style-type: circle">JavaScript & TypeScript.</li>
              <li style="list-style-type: circle">
                MSSQL Server/ EF Core & MongoDB.
              </li>
              <li style="list-style-type: circle">Dart.</li>
            </ul>
          </li>
          <li style="list-style-type: square">
            <span style="font-weight: 500">Frameworks</span>
            <ul>
              <li style="list-style-type: circle">
                .NET Core, Web API, Blazor, EF Core, MVC, Web Form, WPF.
              </li>
              <li style="list-style-type: circle">VueJS, NextJS & ReactJS.</li>
              <li style="list-style-type: circle">CMS: Sitefinity, Umbraco.</li>
              <li style="list-style-type: circle">Flutter.</li>
            </ul>
          </li>
          <li style="list-style-type: square">
            <span style="font-weight: 500">Others</span>
            <ul>
              <li style="list-style-type: circle">Methodologies: Agile.</li>

              <li style="list-style-type: circle">
                Soft skills: solving problems, lead small team (6 peoples),
                teamwork, communication.
              </li>
              <li style="list-style-type: circle">
                Windows Server management / Azure DevOps, GitHub, GitLab, SVN.
              </li>
              <li style="list-style-type: circle">
                Familiar with Linux (Centos OS, Ubuntu), MacOS.
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="side-block" id="skills" style="margin-top: 25px">
        <h1><i class="fa fa-star" aria-hidden="true"></i> INTERESTS</h1>
        <ul>
          <li style="list-style-type: circle">
            Explore new things or new technology in work and in life.
          </li>
          <li style="list-style-type: circle">
            When have an idea in my head, attempt to make it a reality.
          </li>
          <li style="list-style-type: circle">
            Favorite: technology, music, and movies.
          </li>
        </ul>
      </div>
      <div class="side-block" id="disclaimer">
        NGUYEN CHI LINH's résumé was wholly typeset with VueJS — see
        <code>https://linh.work</code>
      </div>
    </aside>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
